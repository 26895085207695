import Header from './Header';
import Footer from './Footer';
import ProductList from './ProductList';
import Product from './Product';
import './App.css';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";


function App() {
  return (
    <Router>
      
        <Header/>
        <Switch>
          <Route path="/productos/:name">
            <Product/>
          </Route>
          <Route path="/productos">
            <ProductList/>
          </Route>
          <Route path="/">
            <div className="App">
            </div>
          </Route>
        </Switch>
        <Footer/>
      
    </Router>
  );
}

export default App;
