import React, { useState, useEffect } from 'react'
import CssBaseline from '@mui/material/CssBaseline';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';

export default function Header(

) {
    return (
        <header className="App-header">
            <CssBaseline />
            <AppBar position="relative">
                <Toolbar>
                Nuestros Productos
                </Toolbar>
            </AppBar>
        </header>
    )
}