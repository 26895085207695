import React, { useState, useEffect } from 'react'
import './Footer.css';

export default function Header(

) {
    return (
        
        <footer className="App-footer">
        </footer>
    )
}