import React, { useState, useEffect } from 'react'
import './ProductList.css'
import Product from './Product';
import Skeleton from '@mui/material/Skeleton';
import Box from '@mui/material/Box';


function ProductList() {
    // ir a pagina de detalles del producto
    const onClickProduct = function(e){
        e.preventDefault();
        console.log(e);
    }

    return (
        <Box>
            {
            Product ? (
                <div onClick={onClickProduct}>
                    <Product/>
                </div>
            )
            : (
                <>
                <Skeleton variant="text" />
                <Skeleton variant="rectangular" height={118}/>
                <Skeleton variant="text" />
                <Skeleton variant="text" />
                <Skeleton variant="text" />
                </>
            )
            }
        </Box>
    )
}

export default ProductList
